angular.module('bfControllers.marketplace', [])
.controller('MarketPlaceController', [
    '$scope',
    '$rootScope',
    '$http',
    '$resource',
    '$cookies',
    'handbook',
    'request',
    'order',
    'online',
    'commet',
    'utils',
    '$window',
    'helpers',
    'counter',
    function($scope, $rootScope, $http, $resource, $cookies, handbook, request, order, online, commet, utils, $window, helpers, counter){

    $rootScope.setMarketMode($window.MODE);

    $scope.handbook = handbook;
    $scope.request = request;
    $scope.order = order;
    $scope.online = online;
    $scope.commet = commet;

    $rootScope.counter = counter;
    $rootScope.sellersOnCurrentPage = [];
    $rootScope.onlineSellers = online.onlineSellers;
    $rootScope.isUserOnlineClass = online.isUserOnlineClass;

    $rootScope.applySellerOnline = function (userId) {
        if (typeof userId === 'undefined' || $rootScope.sellersOnCurrentPage.indexOf(userId) !== -1) {
            $scope.$apply();
        }
    };

    // для МП определим активные режимы
    $rootScope.activePurposes = [0, 1, 2];

    $scope.listCargo = {};
    $scope.listSubmark = {};
    $scope.orders = {};
    $scope.requests = {};

    $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE,41);

    $rootScope.pause = false;

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.platform_id = PLATFORM_ID;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    $rootScope.opening = true; // Для действий при первой загрузке (списка заявок)

    var self = this;

    this.showRequestForm = function(reqid, theme, priceid, buyer_id, offer){
        if ($rootScope.current_user_role === $rootScope.role_seller) {
            $scope.request.clone_request = false;
            $scope.request.set(reqid, 0, priceid, buyer_id);

            $('.bf-create-request-dialog').modal('show');
        } else {
            $resource(API_LINK + '/orders/getActive',
                {},
                {get: {method:'GET'}}
            ).get(function(result){
                $scope.orders = result.data.orders[$rootScope.order_purpose];
                var active_order = self.getAnyActiveOrder();
                if (active_order != 0) {
                    $scope.request.clone_request = false;
                    $scope.request.set(reqid, active_order, priceid);
                    // Открываем диалог
                    $('.bf-create-request-dialog').modal('show');
                } else {
                    order.set(-1, 0, $rootScope.order_purpose);
                    $('.bf-create-order-dialog').modal('show');
                }

                if (typeof theme != 'undefined' && !theme) {
                    $scope.request.buy = true;
                } else {
                    $scope.request.buy = false;
                }
            });
        }
        
        if(typeof theme != 'undefined' && theme == 0){
            $scope.request.formType = 'offer';
        }else if(theme == 'create'){
            $scope.request.formType = 'create';
        }else if(theme == 'edit'){
            $scope.request.formType = 'edit';
        }else if(theme == 'counteroffer'){
            $scope.request.formType = 'counteroffer';    
        }else {
            $scope.request.formType = 'deal';
        }
    };
    $rootScope.create = this.showRequestForm;

    this.showImportRequestForm = function(import_item_id){
        console.log(import_item_id);

        $resource(API_LINK + '/orders/getActive',
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.orders = result.data.orders[$rootScope.order_purpose];
            var active_order = self.getAnyActiveOrder();
            if (active_order != 0) {
                $scope.request.clone_request = false;
                $scope.request.setImportRequest(import_item_id);
                // Открываем диалог
                $('.bf-create-import-request-dialog').modal('show');
            } else {
                order.set(-1, 0, $rootScope.order_purpose);
                $('.bf-create-order-dialog').modal('show');
            }
        });

        //if ($rootScope.current_user_role === $rootScope.role_seller) {
            //$('.bf-create-import-request-dialog').modal('show');

            /*
            $resource(API_LINK + '/orders/getActive',
                {},
                {get: {method:'GET'}}
            ).get(function(result){
                $scope.orders = result.data.orders[$rootScope.order_purpose];
                var active_order = self.getAnyActiveOrder();
                if (active_order != 0) {
                    $scope.request.clone_request = false;
                    $scope.request.set(reqid, active_order, priceid);
                    // Открываем диалог
                    $('.bf-create-request-dialog').modal('show');
                } else {
                    order.set(-1, 0, $rootScope.order_purpose);
                    $('.bf-create-order-dialog').modal('show');
                }

                if (typeof theme != 'undefined' && !theme) {
                    $scope.request.buy = true;
                } else {
                    $scope.request.buy = false;
                }
            });
            */
    };
    $rootScope.createFromImport = this.showImportRequestForm;

    this.resendRequest = function(reqid) {
        $("#resend-btn-" + reqid).attr("disabled", true);

        $resource(API_LINK + '/requests/reSend',
            {id: reqid},
            {get: {method:'POST'}}
        ).get(function(result){
            $rootScope.timerCountSec = 0;
        });

        alert('Запрос плантации направлен.');
    };
    $rootScope.resendRequest = this.resendRequest;

    /**
     * Показывать ли кнопку покупки
     *
     * @param row
     * @param isPriceList
     * @param userId
     * @returns {boolean}
     */
    $scope.showMpButton = function (row, isPriceList, userId) {
        if (row.status !== 'on_market') {
            return false;
        }

        if (row.blocked == 1) {
            return false;
        }

        if (row.rid == 0 && isPriceList === false || row.rid != 0 && isPriceList === true) {
            return false;
        }


        return row.can_edit === 1 || ((($rootScope.current_user_role === $rootScope.role_seller && row.user_id === userId) || $rootScope.current_user_role === $rootScope.role_buyer) && $rootScope.current_user_role !== row.order_type);
    };

    $rootScope.querySent = false;

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('sort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'utime';
    }
    var directValueFromCookies = $cookies.get('direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
    }
    this.setSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc', {path: self.path_alias});
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc', {path: self.path_alias});
            }
        }else{
            // Изменяем сортировку
            $cookies.put('sort', sort_column, {path: self.path_alias});
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;
    
    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };

    /**
     * Возвращает IDs заявок на текущей странице грида
     */
    this.getGridRequestIds = function() {
        return utils.getGridEntityIds($scope.requests);
    };

    // Инит счётчика таймера
    $rootScope.timerCountSec = TIMER_RENEW_PERIOD;

    // Renew timer function
    $rootScope.renewRequests = $scope.pagination.renewPage = function() {

        // Только экспортные коробки по Голландии
        if($rootScope.platform_id == 7 && (typeof $rootScope.filter_value.show_select === 'undefined' || $rootScope.filter_value.show_select == 'all')) {
            $rootScope.filter_value.show_select = 'export';
        }

        // только в режиме торгов
        if (!$rootScope.marketMode_priceList) {
            commet.untrackMarketplaceRequests(self.getGridRequestIds());
        }

        $rootScope.saveFiltersValueInCookies();

        // Get list requests
        $scope.action_for_resource = {
            action:'marketPlace',
            'show[]': $rootScope.filter_value.show_select,
            market_mode: $rootScope.showMarketMode(),
            sort: $rootScope.sort,
            direct: $rootScope.direct,
            hideCompetitors: false,
            onlyTrusted: false,
            efiltersChecks: false,
            limit: $scope.pagination.itemsPerPage,
            page: $scope.pagination.currPage,
            order_purpose: $rootScope.order_purpose
        };
        if(typeof $rootScope.filter_value.plantations != 'undefined') $scope.action_for_resource['farm[]'] = $rootScope.filter_value.plantations;
        if(typeof $rootScope.filter_value.types != 'undefined') $scope.action_for_resource['type[]'] = $rootScope.filter_value.types;
        if(typeof $rootScope.filter_value.sorts != 'undefined') $scope.action_for_resource['variety[]'] = $rootScope.filter_value.sorts;
        if(typeof $rootScope.filter_value.colors != 'undefined') $scope.action_for_resource['color[]'] = $rootScope.filter_value.colors;
        if(typeof $rootScope.filter_value.sizes != 'undefined') $scope.action_for_resource['size[]'] = $rootScope.filter_value.sizes;
        if(typeof $rootScope.filter_value.description != 'undefined' && $rootScope.filter_value.description != '') $scope.action_for_resource['description'] = $rootScope.filter_value.description;
        if(typeof $rootScope.filter_value.gardener != 'undefined' && $rootScope.filter_value.gardener != '') $scope.action_for_resource['gardener'] = $rootScope.filter_value.gardener;
        $rootScope.querySent = true;

        $resource(
            API_LINK + '/:action'
        ).get(
            $scope.action_for_resource,
            function(result){
                //console.log(result);
                if(result.$status == 302){
                    console.log(result);
                }else{
                    $scope.requests = result.data.list;
                    var count = parseInt(result.data.count_items);
                    var total = parseInt(result.data.total_items);
                    $scope.pagination.recountPages(count, total);
                    $rootScope.querySent = false;
                    $rootScope.timerCountSec = TIMER_RENEW_PERIOD;

                    $rootScope.counts = total;

                    $rootScope.sellersOnCurrentPage = [];

                    for (var i = 0; i < $scope.requests.length; i++) {
                        if ($scope.requests.hasOwnProperty(i) && $rootScope.sellersOnCurrentPage.indexOf(parseInt($scope.requests[i].user_id))) {
                            $rootScope.sellersOnCurrentPage.push(parseInt($scope.requests[i].user_id));
                        }
                    }

                    // только в режиме торгов
                    if (!$rootScope.marketMode_priceList) {
                        commet.trackMarketplaceRequests(self.getGridRequestIds());
                    }
                    self.buttonsActiveOff();
                }
            },
            function (error) {
                 console.log('error1');
                 console.log(error);
            }
        );

        // Get Filter Counters
        $scope.action_for_resource2 = {
            action:'filterCounters',
            'show[]': $rootScope.filter_value.show_select,
            market_mode: $rootScope.showMarketMode(),
            hideCompetitors: false,
            onlyTrusted: false,
            efiltersChecks: false,
            order_purpose: $rootScope.order_purpose
        };
        if(typeof $rootScope.filter_value.plantations != 'undefined') $scope.action_for_resource2['farm[]'] = $rootScope.filter_value.plantations;
        if(typeof $rootScope.filter_value.types != 'undefined') $scope.action_for_resource2['type[]'] = $rootScope.filter_value.types;
        if(typeof $rootScope.filter_value.sorts != 'undefined') $scope.action_for_resource2['variety[]'] = $rootScope.filter_value.sorts;
        if(typeof $rootScope.filter_value.sizes != 'undefined') $scope.action_for_resource2['size[]'] = $rootScope.filter_value.sizes;
        if(typeof $rootScope.filter_value.colors != 'undefined') $scope.action_for_resource2['color[]'] = $rootScope.filter_value.colors;
        $resource(API_LINK + '/requests/:action',
            $scope.action_for_resource2,
            {get: {method:'GET'}}
        ).get(function(result){
            $rootScope.filter_counters = result.data;
            $scope.$broadcast('rebuild:me');
            //console.log($scope.requests);
        });
    };

    if ($rootScope.activePurposes.indexOf($rootScope.order_purpose) === -1) {
        $rootScope.setOrderPurpose(0);
    }

    /**
     * Текущий статус у заявки в гриде
     *
     * @param data
     * @returns {*}
     */
    this.getCurrentMarketplaceRequestStatus = function (data) {
        for (var i in $scope.requests) {
            if (!$scope.requests.hasOwnProperty(i)) {
                continue;
            }

            if (data.guid === $scope.requests[i].guid) {
                return $scope.requests[i].status;
            }
        }

        return false;
    };

    /**
     * Текущее кол-во коробок у заявки
     *
     * @param data
     * @returns {*}
     */
    this.getCurrentMarketplaceRequestBoxes = function (data) {
        for (var i in $scope.requests) {
            if (!$scope.requests.hasOwnProperty(i)) {
                continue;
            }

            if (data.guid === $scope.requests[i].guid) {
                return parseFloat($scope.requests[i].boxes_amount);
            }
        }

        return false;
    };

    /**
     * Возвращает разницу, между текущим значением коробок у заявки и новым
     * Тем самым получаем дельту, на которую необходимо изменить значение в фильтре
     *
     * @param data
     * @returns {number}
     */
    $rootScope.getBoxesAmountDelta = function (data) {
        var boxesDelta = 0;

        var currentStatus = self.getCurrentMarketplaceRequestStatus(data);
        if (currentStatus !== false) {
            if (currentStatus === 'off_market' && data.status === 'off_market') {
                boxesDelta = 0;
            } else if (currentStatus === 'off_market' && data.status === 'on_market') {
                boxesDelta = parseFloat(data.boxes_amount);
            } else if (currentStatus === 'on_market' && data.status === 'off_market') {
                var currentBoxes = self.getCurrentMarketplaceRequestBoxes(data);
                boxesDelta -= currentBoxes;
            } else {
                var currentBoxes = self.getCurrentMarketplaceRequestBoxes(data);
                boxesDelta = parseFloat(data.boxes_amount) - currentBoxes;
            }
        } else {
            boxesDelta = parseFloat(data.boxes_amount);
        }

        return boxesDelta;
    };

    /**
     * Изменение состояния заявки в гриде
     *
     * @param data
     */
    $rootScope.changeMarketplaceRequestInGrid = function (data) {
        // если не в режиме торгов, ничего не меняем
        if ($rootScope.showMarketMode() !== 'market') {
            return false;
        }

        if (utils.changeEntityInGrid(data, $scope.requests)) {
            $scope.$apply();
        }
    };

    /**
     * Действия, которые необходимо выполнить после восстановления соединения с сервером ноды
     */
    $rootScope.$on('afterReconnect', function(event, data) {
        $rootScope.renewRequests();
    });


    this.resourceError = function(error){
        console.log(error);
    };

    this.setActiveTab = function (tab, plantation_id) {
        $('#'+ tab +'-requests').prop("checked", true).parent().addClass("active").siblings().removeClass("active");
        $rootScope.setSelect(tab, plantation_id);
    };
    $rootScope.setActiveTab = this.setActiveTab;

    this.setSelect = function (show_select, plantation_id) {
        $rootScope.filter_value.show_select = show_select;

        if (typeof plantation_id !== 'undefined') {
            if (plantation_id > 0) {
                $rootScope.filter_value = {
                    plantations: [plantation_id],
                    types: [],
                    sorts: [],
                    sizes: [],
                    show_select: show_select,
                    description: '',
                    gardener: ''
                };
            } else {
                //$rootScope.filter_value.plantations = [];
                $rootScope.filter_value.show_select = show_select;
            }

            $rootScope.$broadcast('filterChanged', 'availability');
        }

        $rootScope.renewRequests();
    };
    $rootScope.setSelect = this.setSelect;

    $scope.renewTimer = function(){
        if(!$rootScope.pause) $rootScope.timerCountSec--;
        if($rootScope.timerCountSec <= 0){
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $scope.renewRequests();
        }
        $('#timer_value').text($rootScope.timerCountSec);       
        setTimeout($scope.renewTimer, 1000);
    };

    $rootScope.setActiveTab($rootScope.filter_value.show_select);
    $scope.renewTimer();

    // Get all active orders for user
    // Get list orders
    // http://ec.term.biflorica:8080/api/orders/?startDate=01.08.2015&endDate=19.10.2015&start=NaN&sort=created_at&show%5B%5D=all&direct=desc&limit=127&contentType=json
    /*$resource(API_LINK + '/:action',
        {
            action:'orders',
            'show[]': 'all',
            //startDate: $scope.ordersFromDate,
            //endDate: $scope.ordersEndDate,
            sort: 'created_at',
            //limit: ITEMS_SHOW_PER_PAGE,
            start: 0,
            direct: 'desc'
        },
        {get: {method:'GET'}}
    ).get(function(result){
        $scope.orders = result.data.list;
        $scope.order = $scope.orders[0];
    });*/

    // Get Active Orders List
    // http://ec.term.biflorica:8080/api/orders/getActive
    /* BIF-4646 Убрать запросы к API
    this.getActiveOrders = function(){
        $resource(API_LINK + '/orders/getActive',
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.orders = result.data.orders[$rootScope.order_purpose];
            $scope.all_orders = result.data.orders;
        });
    };
    this.getActiveOrders();
    */

    this.setPurpose = function (purpose) {
        $rootScope.setOrderPurpose(purpose);
        $rootScope.renewRequests();
    };
    $rootScope.setPurpose = this.setPurpose;

    this.togglePause = function () {
        if($rootScope.pause){
            $rootScope.pause = false;
        }else{
            $rootScope.pause = true;
        }
        self.buttonsActiveOff();
    };
    $rootScope.togglePause = this.togglePause;

    this.buttonsActiveOff = function(){
        $('.btn-reset-if-renew').blur();
    };

    /**
     * Возвращает последний активный ордер из существующих
     */
    this.getAnyActiveOrder = function(){
        var last_order_id = 0;
        for(var orderid in $scope.orders){
            last_order_id = orderid;
        }
        return last_order_id;
    };

    this.getColorCSS = function(request_row){
        return ["flower", "color-"+request_row.color_id]
    };

    this.cancelAvailabilityApproveForm = function () {
        $('.bf-cancel-availability-approve-dialog').modal('show');
    };
    $rootScope.cancelAvailabilityApproveForm = this.cancelAvailabilityApproveForm;

    this.cancelAvailability = function () {
        $http({
            method: 'POST',
            url: API_LINK + '/requests/cancelAvailability',
            //data: $.param(self.editedRequest),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            $('.bf-cancel-availability-approve-dialog').modal('hide');
            //console.log(result);
        }, function(err){
            //console.log(err);
        });
    };
    $rootScope.cancelAvailability = this.cancelAvailability;

    this.openSpecialRequest = function () {
        if($rootScope.opening && open_request != 0){
            console.log(open_request);
            $('.offer-'+open_request).click();
            $rootScope.opening = false;
        }
    };
    $rootScope.openSpecialRequest = this.openSpecialRequest;

/*
    $scope.buttonClick = function(request_row, button){
        switch(button.actiontype){
            case 'approvecancel':
                request.approveCancel(request_row.deal_id);
                break;
            case 'approvechange':
                request.approveChange(request_row.guid_seller, request_row.order_id_seller);
                $('.bf-create-request-dialog').modal('show');
                break;
            default:
                break;
        }
    };
*/
} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
})
.directive( 'elemReady', function( $parse ) {
    return {
        restrict: 'A',
        link: function( $scope, elem, attrs ) {
            elem.ready(function(){
                $scope.$apply(function(){
                    var func = $parse(attrs.elemReady);
                    func($scope);
                })
            })
        }
    }
})
;
