angular.module('bfControllers.price', [])
.controller('PriceController', ['$scope', '$rootScope', '$http', '$resource', '$cookies', 'handbook', 'price', function ($scope, $rootScope, $http, $resource, $cookies, handbook, price) {

    $scope.inlineData = {}
    $scope.errors = {}

    $scope.price = price;
    $scope.pricelist = {};
    $scope.pricelistLength = 0;
    $scope.groups = {};
    $scope.count = 0;
    $scope.start = 0;
    $rootScope.create = price.set;

    $scope.price_editing_guid = -1;

    $scope.selected_prices = {
        ids: []
    };

    $scope.price.editedPrice.groups = {};

    $scope.is_set_prices = 1;
    $scope.price_id_variety_list = {};

    $rootScope.handbook = handbook;

    this.path_alias = '/marketPlaceNew/default/pricelist';

    var self = this;
    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('pricesort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'variety';
        $cookies.put('pricesort', 'variety', {path: self.path_alias});
    }
    var directValueFromCookies = $cookies.get('direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'asc';
        $cookies.put('direct', 'asc', {path: self.path_alias});
    }
    this.setSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc', {path: self.path_alias});
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc', {path: self.path_alias});
            }
        }else{
            // Изменяем сортировку
            $cookies.put('pricesort', sort_column, {path: self.path_alias});
            $rootScope.sort = sort_column;
        }
        $rootScope.renewItems();
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };

    $scope.isPriceEditing = function (group_id, num) {
        return $scope.price_editing_guid == group_id + '|' + num
    }

    $scope.applyChanges = function () {

        url = API_LINK + '/priceList/update/';
        var sent_params = $scope.inlineData
        sent_params.selectedSizes = ["35", "40", "50", "60", "70", "80", "90", "100", "100p"]
        sent_params.managed = "0"
        sent_params.id = sent_params.guid

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){

            result = result.data;

            if( result.errors.length == 0) {
                $scope.errors = {}

                const parts = $scope.price_editing_guid.split("|")

                $scope.pricelist[parts[0]][parts[1]] = $scope.inlineData
                $scope.price_editing_guid = -1
            } else {
                $scope.errors = result.fieldsErrors
            }
        }, function(err){
            console.log(err);
        });
    };

    $scope.isError = function(field) {
        return $scope.errors.hasOwnProperty(field)
    }

    $scope.cancelEdit = function () {
        $scope.price_editing_guid = -1
    };

    $scope.inlineEdit = function (group_id, num) {
        $scope.price_editing_guid = group_id + '|' + num
        $scope.inlineData = {...$scope.pricelist[group_id][num]}
    };

    /**
     * Get list pricelist
     * http://ec.term.biflorica:8080/api/priceList/?start=0&sort=variety&direct=asc&show%5B%5D=all&limit=127
     */
    $rootScope.renewItems = function() {
        $rootScope.querySent = true;
        $resource(API_LINK + '/:action',
            {
                action: 'priceList',
                'show[]': 'all',
                //startDate: $scope.ordersFromDate,
                //endDate: $scope.ordersEndDate,
                sort: $rootScope.sort,
                limit: ITEMS_SHOW_PER_PAGE,
                start: $scope.start,
                direct: $rootScope.direct,
                managed: $rootScope.manage.plantation
            },
            {get: {method: 'GET'}}
        ).get(function (result) {
            $scope.pricelist = result.data.list;
            $scope.count = result.data.count;
            $scope.groups = result.data.groups;
            console.log($scope.pricelist);
            $rootScope.querySent = false;

            $scope.pricelistLength = 0;
            for (var group in $scope.pricelist) {
                for (var i in $scope.pricelist[group]) {
                    $scope.price_id_variety_list[$scope.pricelist[group][i].guid] = $scope.pricelist[group][i].variety;
                    $scope.pricelistLength++;
                }
            }
        });
    };
    $rootScope.renewItems();

    $scope.orderFormTitleCreate = function(){
        return typeof $scope.order.editedOrder.guid == 'undefined' || $scope.order.editedOrder.guid == '-1';
    };

    /**
     * Delete prices
     */
    $rootScope.deleteItem = function () {
        url = API_LINK + '/priceList/delete/';

        var sent_params = {};
        sent_params['ids[]'] = $scope.selected_prices.ids;
        sent_params['managed'] = $rootScope.manage.plantation;

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.status == 200) {
                location.reload();
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_prices.ids = [];
        if($('#list-prices-all').is(':checked')){
            for (var group in $scope.pricelist) {
                for (var price in $scope.pricelist[group]) {
                    $scope.selected_prices.ids.push($scope.pricelist[group][price].guid);
                }
            }
            $('.order-checkbox').attr('checked', true);
        }
        console.log($scope.selected_prices);
    };

    $scope.isGroupChecked = function (group_id) {
        for (var price in $scope.pricelist[group_id]) {
            if ($scope.selected_prices.ids.indexOf($scope.pricelist[group_id][price].guid) === -1) {
                return false;
            }
        }

        return true;
    };

    $scope.selectGroupItems = function (group_id) {
        var is_group_checked = $scope.isGroupChecked(group_id);

        for (var price in $scope.pricelist[group_id]) {
            var el = $scope.pricelist[group_id][price].guid;
            if (!is_group_checked && $scope.selected_prices.ids.indexOf(el) === -1) {
                $scope.selected_prices.ids.push(el);
            } else if (is_group_checked) {
                $scope.removeItem(el);
            }
        }

        $scope.renewButtonsStatus();
    };

    $scope.removeItem = function (price) {
        var index = $scope.selected_prices.ids.indexOf(price);

        if (index === -1) {
            return false;
        }

        delete $scope.selected_prices.ids.splice(index, 1);

        // close all modal window if all selected items have been removed
        if ($scope.selected_prices.ids.length === 0) {
            $('#setPrices').modal('hide');
        }

        return true;
    };

    /**
     * Обновить статус кнопок груповых операций
     */
    $scope.renewButtonsStatusPrice = function () {
        if($scope.selected_prices.ids.length == 0){
            // Disable buttons
            $('.group-prices-btn').attr('disabled', 'disabled');
        }else{
            // Enable buttons
            $('.group-prices-btn').removeAttr('disabled');

        }
        console.log($scope.selected_prices);
    };

    /**
     * Move to group (save)
     */
    $rootScope.moveToSelectedGroup = function () {
        var sent_params = {};
        sent_params['managed'] = $rootScope.manage.plantation;
        sent_params['group_id'] = $scope.price.editedPrice.group_id;
        sent_params['name'] = $scope.price.editedPrice.name;
        sent_params['ids[]'] = $scope.selected_prices.ids;

        $http({
            method: 'POST',
            url: API_LINK + '/priceList/moveToGroup',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function(result) {
            console.log(result);
            result = result.data;
            if (result.errors.length == 0) {
                $scope.price.clearErrors();
                $('#moveToGroup').modal('hide');
                $rootScope.renewItems();
            } else {
                $scope.price.priceFormErrors = result.errors[0];
                $scope.price.priceFormFieldsErrors = result.fieldsErrors;
            }
        }, function(err) {
            console.log(err);
        });
    };

    /**
     * Move to group (show form)
     */
    $rootScope.moveToGroup = function () {
        //Очищаем ошибки на форме
        $scope.price.clearErrors();
        $scope.price.editedPrice.group_id = '';
        $scope.price.editedPrice.name = '';

        var sent_params = {};
        sent_params['managed'] = $rootScope.manage.plantation;

        //Очищаем ошибки на форме
        $scope.price.clearErrors();

        $resource(
            API_LINK + '/priceList/getGroups',
            sent_params,//{action:'new', 'request': request_id},
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.price.editedPrice.groups = result.data.groups;

            $('#moveToGroup').modal('show');
            $rootScope.timerCountSec = 0;
            //console.log(self.editedPrice);
        });
    };

    /**
     * Change price to some prices (show form)
     */
    $rootScope.changePrices = function () {
        //Очищаем ошибки на форме
        $scope.price.clearErrors();
        $scope.price.editedPrice.delta = '0.00';
        $('#changePrices').modal('show');
        $rootScope.timerCountSec = 0;
    };

    /**
     * Save changed prices
     */
    $rootScope.saveChangedPrices = function () {
        var sent_params = {};
        sent_params['managed'] = $rootScope.manage.plantation;
        sent_params['delta'] = $scope.price.editedPrice.delta;
        sent_params['ids[]'] = $scope.selected_prices.ids;

        $http({
            method: 'POST',
            url: API_LINK + '/priceList/changePrices',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function(result) {
            console.log(result);
            result = result.data;
            if (result.errors.length == 0) {
                $scope.price.clearErrors();
                $('#changePrices').modal('hide');
                $rootScope.renewItems();
            } else {
                $scope.price.priceFormErrors = result.errors[0];
                $scope.price.priceFormFieldsErrors = result.fieldsErrors;
            }
        }, function(err) {
            console.log(err);
        });
    };

    /**
     * Set price to some prices (show form for select price)
     */
    $rootScope.setPrices = function (mode) {
        $scope.is_set_prices = mode;
        var sent_params = {};
        sent_params['managed'] = $rootScope.manage.plantation;
        sent_params['ids[]'] = $scope.selected_prices.ids;

        //Очищаем ошибки на форме
        $scope.price.clearErrors();

        $resource(
            API_LINK + '/priceList/getMultiple',
            sent_params,//{action:'new', 'request': request_id},
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.price.editedPrice = result.data.item;
            $scope.price.normallizeVarieties();

            $('#setPrices').modal('show');
            $rootScope.timerCountSec = 0;
            //console.log(self.editedPrice);
        });
    };

    /**
     * Save prices
     */
    $rootScope.savePrices = function () {
        var sent_params = $scope.price.getParamsFromEditedPrice($scope.price.editedPrice);
        sent_params['ids[]'] = $scope.selected_prices.ids;
        sent_params['managed'] = $rootScope.manage.plantation;
        sent_params['is_set_prices'] = $scope.is_set_prices;

        $http({
            method: 'POST',
            url: API_LINK + '/priceList/updateMultiple',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function(result) {
            console.log(result);
            result = result.data;
            if(result.errors.length == 0) {
                $scope.price.clearErrors();
                $('#setPrices').modal('hide');
                $rootScope.renewItems();
            } else {
                $scope.price.priceFormErrors = result.errors[0];
                $scope.price.priceFormFieldsErrors = result.fieldsErrors;
            }
        }, function(err) {
            console.log(err);
        });
    };

    this.getColorCSS = function(request_row){
        return ["flower", "color-"+request_row.color_id]
    };
} ])
.directive('uiSelectChoices', ['$timeout', '$parse', '$compile', '$document', '$filter', function($timeout, $parse, $compile, $document, $filter) {
    return function(scope, elm, attr) {
        var raw = elm[0];
        var scrollCompleted = true;
        if (!attr.allChoices) {
            return false;
            // throw new Error('ief:ui-select: Attribute all-choices is required in  ui-select-choices so that we can handle  pagination.');
        }

        var updateSelected = function () {
            var choices = scope.pagingOptions.allOptions;

            if (choices === undefined)
                return false

            if (choices.length === 0) {
                return false;
            }

            var selected = scope.$select.selected;

            for (var k = 0; k < choices.length; k++) {
                if (selected === choices[k].key) {
                    scope.$select.selected = choices[k];
                    break;
                }
            }
        };

        if (attr.changedValue) {
            scope.$watchCollection(attr.changedValue, function (newChoices, oldChoices) {
                if (newChoices === '') {
                    return false;
                }

                updateSelected();
            });
        }

        scope.pagingOptions = {
            allOptions: scope.$eval(attr.allChoices)
        };

        attr.refresh = 'addMoreItems()';
        var refreshCallBack = $parse(attr.refresh);

        scope.$watchCollection(attr.allChoices, function(newChoices, oldChoices){
            scope.pagingOptions = {
                allOptions: newChoices
            };

            updateSelected();
        });

        elm.bind('scroll', function(event) {
            var remainingHeight = raw.offsetHeight - raw.scrollHeight;
            var scrollTop = raw.scrollTop;
            var percent = Math.abs((scrollTop / remainingHeight) * 100);

            if (percent >= 80) {
                if (scrollCompleted) {
                    scrollCompleted = false;
                    event.preventDefault();
                    event.stopPropagation();
                    var callback = function() {
                        scope.addingMore = true;
                        refreshCallBack(scope, {
                            $event: event
                        });
                        scrollCompleted = true;

                    };
                    $timeout(callback, 100);
                }
            }
        });

        var closeDestroyer = scope.$on('uis:close', function() {
            var pagingOptions = scope.$select.pagingOptions || {};
            pagingOptions.filteredItems = undefined;
            pagingOptions.page = 0;
        });

        scope.addMoreItems = function(doneCalBack) {
            var $select = scope.$select;
            var allItems = scope.pagingOptions.allOptions;

            if (allItems.length === 0) {
                return false;
            }

            var moreItems = [];
            var itemsThreshold = 100;
            var search = $select.search;

            var pagingOptions = $select.pagingOptions = $select.pagingOptions || {
                    page: 0,
                    pageSize: 20,
                    items: $select.items
                };

            if (pagingOptions.page === 0) {
                pagingOptions.items.length = 0;
            }
            if (!pagingOptions.originalAllItems) {
                pagingOptions.originalAllItems = scope.pagingOptions.allOptions;
            }
            // console.log('search term=' + search);
            // console.log('prev search term=' + pagingOptions.prevSearch);
            var searchDidNotChange = typeof search === 'string' && search.length > 0
                && typeof pagingOptions.prevSearch === 'string' && pagingOptions.prevSearch.length > 0
                && search == pagingOptions.prevSearch;
            // console.log('searchDidNotChange=' + searchDidNotChange);
            if (pagingOptions.filteredItems && searchDidNotChange) {
                allItems = pagingOptions.filteredItems;
            }
            pagingOptions.prevSearch = search;
            if (search && search.length > 0 && !searchDidNotChange) {
                //search

                if (!pagingOptions.filteredItems) {
                    //console.log('previous ' + pagingOptions.filteredItems);
                }

                pagingOptions.filteredItems = undefined;
                moreItems = $filter('filter')(allItems, search);
                //if filtered items are too many scrolling should occur for filtered items
                if (moreItems.length > itemsThreshold) {
                    if (!pagingOptions.filteredItems) {
                        pagingOptions.page = 0;
                        pagingOptions.items.length = 0;
                    } else {

                    }
                    pagingOptions.page = 0;
                    pagingOptions.items.length = 0;
                    allItems = pagingOptions.filteredItems = moreItems;

                } else {
                    allItems = moreItems;
                    pagingOptions.items.length = 0;
                    pagingOptions.filteredItems = undefined;
                }


            } else {
                // console.log('plain paging');
            }

            var offset = pagingOptions.page * pagingOptions.pageSize;
            if (offset < allItems.length) {
                pagingOptions.page++;

                offset = pagingOptions.page * pagingOptions.pageSize;
                if (offset > allItems.length) {
                    offset = allItems.length;
                }

                moreItems = allItems.slice(pagingOptions.items.length, offset);
            }

            for (var k = 0; k < moreItems.length; k++) {
                pagingOptions.items.push(moreItems[k]);
            }

            scope.calculateDropdownPos();
            scope.$broadcast('uis:refresh');
            if (doneCalBack) doneCalBack();
        };
        scope.$on('$destroy', function() {
            elm.off('scroll');
            closeDestroyer();
        });
    };
}]);